/* eslint-disable @typescript-eslint/no-empty-interface -- https://twitter.com/mattpocockuk/status/1760991147793449396 */
/* eslint-disable @typescript-eslint/no-namespace -- https://twitter.com/mattpocockuk/status/1760991147793449396 */
import { z } from 'zod';

/**
 * Feature flags configuration
 * Add new feature flags here with their environment variable names
 */
const featureFlagsSchema = z.object({
    NEXT_PUBLIC_ENABLE_DUPLICATE_EVENT: z.enum(['true', 'false']).optional(),
    // Add more feature flags here
});

const envVariables = z
    .object({
        API_BACKEND: z.string(),
        NEXT_PUBLIC_API_BASE_PATH: z.string(),
        NEXT_PUBLIC_API_VERSION: z.string(),
        NODE_ENV: z.enum(['development', 'staging', 'production']),
        NEXT_PUBLIC_API_SECRET: z.string(),
        NEXT_PUBLIC_PAGE_SECRET: z.string(),
        NEXT_PUBLIC_REFERER: z.string().optional(),
        NEXT_PUBLIC_CHECK_IN_URL: z.string().optional(),
        NEXT_PUBLIC_HANDSONTABLE_LICENSE_KEY: z.string().optional(),
        NEXT_PUBLIC_FULL_CALENDAR_LICENSE_KEY: z.string().optional(),
    })
    .merge(featureFlagsSchema);

type EnvVariables = z.infer<typeof envVariables>;

declare global {
    namespace NodeJS {
        interface ProcessEnv extends EnvVariables {}
    }
}

export function init() {
    const parsed = envVariables.safeParse(process.env);

    if (!parsed.success) {
        // eslint-disable-next-line no-console -- Show a warning to the user
        console.error('❌ Invalid environment variables:', parsed.error.flatten().fieldErrors);

        throw new Error('Invalid environment variables');
    }
}

/**
 * Get all feature flags with their current values
 * This is used by the FeatureFlagsProvider
 */
export function getFeatureFlags() {
    return {
        enableDuplicateEvent: process.env.NEXT_PUBLIC_ENABLE_DUPLICATE_EVENT === 'true',
        // Add more feature flags here
    } as const;
}

/**
 * This is used in both `entry.server.ts` and `root.tsx` to ensure that
 * the environment variables are set and globally available before the app is
 * started.
 *
 * NOTE: Do *not* add any environment variables in here that you do not wish to
 * be included in the client.
 * @returns all public ENV variables
 */
export function getEnv() {
    return {
        API_BACKEND: process.env.API_BACKEND,
        API_BASE_PATH: process.env.NEXT_PUBLIC_API_BASE_PATH,
        API_VERSION: process.env.NEXT_PUBLIC_API_VERSION,
        ENV: process.env.NODE_ENV,
        API_SECRET: process.env.NEXT_PUBLIC_API_SECRET,
        PAGE_SECRET: process.env.NEXT_PUBLIC_PAGE_SECRET,
        REFERER: process.env.NEXT_PUBLIC_REFERER,
        CHECK_IN_URL: process.env.NEXT_PUBLIC_CHECK_IN_URL,
        HANDSONTABLE_LICENSE_KEY: process.env.NEXT_PUBLIC_HANDSONTABLE_LICENSE_KEY,
        FULL_CALENDAR_LICENSE_KEY: process.env.NEXT_PUBLIC_FULL_CALENDAR_LICENSE_KEY,
    };
}
